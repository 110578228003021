@import '~antd/dist/antd.less';

@header-height: 50px;

body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background-color: #e4e4e4;
}
body::-webkit-scrollbar-thumb {
  background-color: #b9b9b9;
  border-radius: 100px;
  box-shadow: inset 2px 2px 5px 0 rgba(#888888, 0.5);
}
.ant-menu-root::-webkit-scrollbar-track{
  background-color: @layout-sider-background;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.min-vh-100 {
  min-height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: @layout-sider-background;
}

.filter-card-small {
  max-height: 49px;
  overflow: hidden;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.w-100 {
  width: 100% !important;
}
@primary-color: #1DA57A;@layout-header-background: #fff;@layout-sider-background: #00754c;@menu-dark-bg: @layout-sider-background;@menu-dark-highlight-color: #fff;@menu-dark-selected-item-icon-color: #fff;@menu-dark-selected-item-text-color: #fff;